import authService from '../services/authService';
import AES from "crypto-js/aes";
import encUTF8 from "crypto-js/enc-utf8";
import axios from "axios";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

export const RENEW_TOKENS = "RENEW_TOKENS";

const aesSecret = process.env.REACT_APP_AES_SECRET;

const decryptLocalStorage = (string) => JSON.parse(AES.decrypt(string, aesSecret).toString(encUTF8));

export const remember = (user, tokens) => ({ type: LOGIN_SUCCESS, user, tokens });

export const login = (email, password) => {
    return dispatch => {
        axios.post("Auth/loginUser", {email, password})
            .then(response => {
                if (response.status === 200){
                    localStorage.setItem("auth", response.data.auth);
                    const {user, tokens} = decryptLocalStorage(response.data.auth);
                    dispatch({ type: LOGIN_SUCCESS, user: user, tokens: tokens })
                }
            })
            .catch( err => {
                /*console.log("response catch");
                console.log(err.response);*/
                console.log(err)
                dispatch({type: LOGIN_ERROR, error: err.response?.data?.message})
            });

    };
};

export const logout = () => {
    authService.logout();
    return { type: LOGOUT };
};

export const reNewToken = (refreshToken) => {
    authService.reNewToken( refreshToken )
        .then( data => {
            /*dispatch({ type: RENEW_TOKENS, tokens: data })*/
            return data;
        })
        .catch(err => console.log(err))
}