import {LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT, RENEW_TOKENS} from '../actions/authAction';

const initState = {
    user: {},
    tokens: {},
    isAuthenticated: false,
    error: false,
    errorMessage: '',
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                tokens: action.tokens,
                isAuthenticated: true,
                error: false,
                errorMessage: '',
            };
        case LOGIN_ERROR:
            return {
                ...state,
                auth: {},
                isAuthenticated: false,
                error: true,
                errorMessage: action.error,
            };

        case RENEW_TOKENS:
            return { ...state, auth: { ...state.auth, tokens: action.tokens }}
        case LOGOUT:
            return { auth: {} };

        default:
            return state;
    }
};

export default authReducer;