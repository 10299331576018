import axios from 'axios';
import {setAuthToken} from '../helpers/setAuthToken';

const login = async (email, password) => {
    return axios.post("Auth/loginUser", {email, password})
        .then(response => {
            if (response.status === 200)
                localStorage.setItem("auth", response.data.auth);

            return response.data;
        })
        .catch(function (error) {
            console.log("response catch");
            console.log(error.response);
            if (error.response.status === 401) {
                //redirect to login
            }
            return error.response.data;
        });
}

const logout = () => {
    localStorage.removeItem("auth");
    setAuthToken(false);
}

const reNewToken = (refreshToken) => {
    return axios.post("/auth/refresh-tokens", {refreshToken})
        .then(response => response)
        .catch(function (error) {
            console.log("response catch");
            console.log(error.response);
            if (error.response.status === 401) {
                //redirect to login
            }
            return error.response.data;
        });

}

export default {login, logout, reNewToken};