import {getSurveys} from '../services/surveyService';
import axios from "axios";

export const SELECT_SURVEY = "SELECT_SURVEY";

export const CREATE_SURVEY_SUCCESS = "CREATE_SURVEY_SUCCESS";
export const CREATE_SURVEY_ERROR = "CREATE_SURVEY_ERROR";
export const CREATE_SURVEY_START = "CREATE_SURVEY_START";

export const UPDATE_SURVEYS = "UPDATE_SURVEYS";

export const DELETE_SURVEY_START = "DELETE_SURVEY_START";
export const DELETE_SURVEY_ERROR = "DELETE_SURVEY_ERROR";
export const DELETE_SURVEY_HIDE_MODAL = "DELETE_SURVEY_HIDE_MODAL";

export const SET_FULLPAGE = "SET_FULLPAGE";

const surveyAPI = (endpoint, payload) => axios
    .post(endpoint, payload)
    .then(res => res.data)
    .catch(err => err.response.data);

const createSurveyStart = () => ({type: CREATE_SURVEY_START});

export const createSurvey = (survey) => {
    return dispatch => {
        surveyAPI("Survey/createSurvey", survey)
            .then(({survey}) => dispatch({type: CREATE_SURVEY_SUCCESS, survey}))
            .catch(err => {
                alert(err)
            })
    };
};

export const updateSurvey = (survey) => {
    return dispatch => {
        surveyAPI("Survey/updateSurvey", survey)
            .then(({survey}) => dispatch({type: CREATE_SURVEY_SUCCESS, survey}))
            .catch(err => {
                alert(err)
            })
    };
};

export const selectSurvey = selectedSurvey => async dispatch => dispatch({type: SELECT_SURVEY, selectedSurvey});

export const startSurvey = () => {
    return dispatch => {
        dispatch(createSurveyStart());
    };
};

export const updateSurveys = () => async dispatch => dispatch({type: UPDATE_SURVEYS, surveys: await getSurveys()});

// Hide modal when survey is deleted
export const hideModal = () => ({type: DELETE_SURVEY_HIDE_MODAL});

export const deleteSurveyStart = selectedSurvey => dispatch => dispatch({
    type: DELETE_SURVEY_START,
    selectedSurvey
});

// Delete Survey
export const deleteSurvey = (survey) => {
    return dispatch => {
        surveyAPI("Survey/deleteSurvey", {surveyID: survey.id})
            .then(({surveyID}) => {
                if (surveyID === survey.id) {
                    dispatch(hideModal())
                    dispatch(updateSurveys())
                }
            })
            .catch(err => {
                alert(err)
            })
    };
};

export const setIsFullPage = isFullPage => dispatch => dispatch({type: SET_FULLPAGE, isFullPage});