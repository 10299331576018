import {
  SEND_SURVEY_RESULT_SENDING,
} from "../actions/surveyResultAction";

const initState = {
  surveyResult: "",
  sendSurveyResultSuccess: false,
  error: false,
  errorMessage: "",
  isSendingResult: false,
};

const sendSurveyResultReducer = (state = initState, action) => {
  switch (action.type) {
    case SEND_SURVEY_RESULT_SENDING:
      return { ...state, isSendingResult: true };

    default:
      return state;
  }
};

export default sendSurveyResultReducer;
