import axios from "axios";

export const SEND_SURVEY_RESULT_SENDING = "SEND_SURVEY_RESULT_SENDING";

export const sendSurveyResult = (result) => {
    return axios.post('Survey/saveResult', result)
        .then( res => {/*console.log(res)*/} )
        .catch( err => console.log(err) );
};

export const sendSurveySending = () => {
    return dispatch => dispatch({ type: SEND_SURVEY_RESULT_SENDING });
};