import { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import axios from 'axios'
import { baseURL, refreshToken } from './app/services/axiosService'

import { Provider } from 'react-redux'
import store from './app/helpers/store'
import Spinner from './app/components/shared/Spinner'

const FillSurvey = lazy(() => import('./app/components/fillSurvey/FillSurvey'))
const App = lazy(() => import('./app/App'))

axios.defaults.baseURL = baseURL
/*axios.interceptors.response.use(refreshToken);*/

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename='/'>
            <Suspense fallback={<Spinner />}>
                <Switch>
                    <Route exact path='/survey' component={FillSurvey} />
                    <Route component={App} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)