import {
    SELECT_SURVEY,
    CREATE_SURVEY_ERROR,
    CREATE_SURVEY_START,
    CREATE_SURVEY_SUCCESS,
    UPDATE_SURVEYS,
    DELETE_SURVEY_START,
    DELETE_SURVEY_HIDE_MODAL,
    SET_FULLPAGE
} from "../actions/surveyAction";

const initState = {
    selectedSurvey: {},
    surveys: [],
    createSurveySuccess: false,
    error: false,
    errorMessage: "",
    isFullPage: false,
};

const surveyReducer = (state = initState, { type, selectedSurvey, surveys, error, isFullPage}) => {
    switch (type) {
        case SELECT_SURVEY:
            return { ...state, selectedSurvey }

        case CREATE_SURVEY_SUCCESS:
            return {
                ...state,
                selectedSurvey,
                createSurveySuccess: true,
                error: false,
                errorMessage: "",
            };

        case CREATE_SURVEY_ERROR:
            return {
                ...state,
                selectedSurvey: {},
                createSurveySuccess: false,
                error: true,
                errorMessage: error,
            };

        case CREATE_SURVEY_START:
            return {
                ...state,
                selectedSurvey: {},
                createSurveySuccess: false,
                error: false,
                errorMessage: "",
            };

        case UPDATE_SURVEYS:
            return { ...state, surveys };

        case DELETE_SURVEY_START:
            return { ...state, selectedSurvey, showModal: true };

        case DELETE_SURVEY_HIDE_MODAL:
            return { ...state, selectedSurvey: {}, showModal: false };

        case SET_FULLPAGE:
            return { ...state, isFullPage}

        default: return state;
    }
};

export default surveyReducer;
