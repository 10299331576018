import axios from "axios";

export const getSurveys = async () => {

    // Request payload
    const payload = {
        paginate: {
            limit: 10,
            page: 1,
        }
    }

    return axios
        .post("Survey/getSurveys", payload )
        .then( res => res.data.results )
        .catch( err => err.response.data );
};

export const getSurvey = async (surveyID, commonExtId = undefined) => {
    // Request payload
    const payload = { surveyID, commonExtId }

    return axios
        .post(`Survey/getSurvey`, payload )
        .then( res => res.data )
        .catch( err => {
            window.alert(err)
            return err.response.data;
        });
};

const createSurvey = async survey => {

    return axios
        .post("Survey/createSurvey", survey )
        .then( res => res.data )
        .catch( err => err.response.data );

};

const updateSurvey = async survey => {

    // Request payload
    const payload = { updatedSurvey: survey }

    return axios
        .post("Survey/updateSurvey", payload )
        .then( res => res.data )
        .catch( err => err.response.data );
};

const deleteSurvey = async surveyID => {

    // Request payload
    const payload = { surveyID }

    return axios
        .post("Survey/deleteSurvey", payload )
        .then( res => res.data )
        .catch( err => err.response.data );
};

export default {
    createSurvey,
    updateSurvey,
    deleteSurvey
};
