import { combineReducers } from 'redux';

import authReducer from './authReducer';
import surveyReducer from './surveyReducer';
import surveyResultReducer from './surveyResultReducer';

let rootReducer = combineReducers({
    auth: authReducer,
    survey: surveyReducer,
    surveyResult: surveyResultReducer
});

export default rootReducer;